import React from "react";
import BlindadosWrapper from "Components/Blindados";
import { StaticImage } from "gatsby-plugin-image";
import HammerAndBat from 'Images/hammer-bat.svg';
import { Link } from "gatsby";

const BlindajeEstrategicoPage = ({ location }) => {
  const header = <StaticImage src="../../images/blindajes-estrategico-cover.png" transformOptions={{ quality: 100 }} placeholder="blurred" aspectRatio={3/1} />;
  return (
    <BlindadosWrapper title="PROTECCIÓN ANTIVANDÁLICA" location={location} header={header}>
      <p className="_content-paragraph">
        V- Hard, así denominamos a nuestro conjunto de vidrios para la protección antivandálica. Son una excelente solución para niveles de riesgo moderados. La protección resulta suficiente para un asalto callejero. (Mucho más resistente que los antivandálicos que venden actualmente en el mercado). A pesar de no ser un auto blindado, al tener un alto grado de cobertura, un blindaje estratégico resulta una excelente opción costo-beneficio. Actualmente tenemos dos modelos:
      </p>
      <ul className="type--list">
        <li>V- H</li>
        <li>V- H plus</li>
      </ul>
      <Link to="#contacto" className="_accent-text _link">Quiero más información</Link>
      <iframe classname="dangers--video" width="100%" height="315" src="https://www.youtube.com/embed/7Ombzgl0imo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <HammerAndBat className="dangers--icons" />
    </BlindadosWrapper>
  );
};

export default BlindajeEstrategicoPage;
