import React from 'react';
import PageWithSubMenu from 'Commons/PageWithSubmenu';

import './styles.scss';

const Blindados = ({ title, children, location, header = null }) => {
  const currentPath = location.pathname;
  return (
    <PageWithSubMenu
      submenuItems={[
        {
          title: 'BLINDAJES 360',
          to: '/blindajes-360',
        },
        {
          title: 'PROTECCIÓN ANTIVANDÁLICA',
          to: '/blindaje-estrategico',
        },
      ]}
      currentPath={currentPath}
      header={header}
    >
      <header className="blindados-header">
        <h2 className="_sub-heading content--title">{title}</h2>
      </header>
      {children}
    </PageWithSubMenu>
  );
};

export default Blindados;
